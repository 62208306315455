import config from "config";
import AuthenticationContext from "contexts/AuthenticationContext";
import {
  createRef,
  MouseEvent,
  MouseEventHandler,
  useContext,
  useEffect,
  useState,
} from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import sendRequest from "services/dataService";
import { CurrentUserState } from "types";
import { initAnnotator } from "../annotator-utills";
import "./EditCritique.scss";

type AnnotatorNote = {
  user: {
    id: number;
    pen_name: string;
  };
  ranges: {
    start: string;
    startOffset: number;
    end: string;
    endOffset: number;
    quote: string;
    text?: string;
    created?: string;
  }[];
  permissions?: {
    read: any[];
    update: any[];
    delete: any[];
    admin: any[];
  };
};

type SubmissionResponse = {
  document: {
    id: number;
    author: {
      id: number;
      pen_name: string;
    };
    title: string;
    contents: string;
  };
  group: {
    id: number;
    name: string;
  };
};

export default function EditCritique() {
  const authenticationContext = useContext(AuthenticationContext);
  const { id } = useParams() as any;
  const [submission, setSubmission] = useState<SubmissionResponse | undefined>(
    undefined
  );
  const userState = useSelector(
    (state: { user: CurrentUserState }) => state.user
  );
  const [annotatorStarted, setAnnotatorStarted] = useState(false);
  const divRef = createRef<HTMLDivElement>();
  const [marginElements, setMarginElements] = useState<JSX.Element[]>([]);

  async function loadSubmission() {
    const url = `${config.BASE_API_URL}api/v1.0/submission/${id}/`;
    const response: SubmissionResponse | undefined = await sendRequest({
      url,
      method: "GET",
      token: authenticationContext.token || "",
      body: null,
    });
    setSubmission(response);
  }

  useEffect(() => {
    if (
      divRef.current &&
      userState.data.id &&
      id &&
      !annotatorStarted &&
      authenticationContext.token
    ) {
      setAnnotatorStarted(true);
      initAnnotator(
        userState.data,
        id,
        divRef.current,
        authenticationContext.token
      );
    }
  }, [divRef, userState, id, annotatorStarted, authenticationContext]);

  useEffect(() => {
    if (authenticationContext.token && id) {
      loadSubmission();
    }
  }, [id, authenticationContext]);

  return (
    <>
      <Container>
        <Row>
          <Col xs={12} lg={{ span: 10, offset: 1 }}>
            <a
              href={`${config.BASE_API_URL}/group/${submission?.group.id}/test-group/`}
              id="returntogroup"
            >
              {" "}
              Return to {submission?.group.name}
            </a>
          </Col>
        </Row>

        <Row>
          <Col
            xs={12}
            className="justify-between items-start border-b pb-2 mb-4"
          >
            <div>
              <h4>{submission?.document.title}</h4>
              <h5 className="text-gray-500">
                {submission?.document.author.pen_name}
              </h5>
            </div>
            <div className="text-right">
              <p className="text-sm text-gray-500">Due 12/Aug 23:59 (EDT)</p>
              <a href="#" className="text-blue-600 text-sm" rel="ugc">
                View all critiques
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={8} ref={divRef}>
            <div
              id="annotated_content"
              className="flex-1 pr-8 annotated_content"
              dangerouslySetInnerHTML={{
                __html: submission?.document.contents || "",
              }}
            ></div>
          </Col>
          <Col xs={12} md={4}>
            {/* Margin Viewer container */}
            <div className="w-64 border-l pl-4 margin-annotator-container">
              <div className="secondary margin-annotator-container"></div>
            </div>
          </Col>
        </Row>
      </Container>
      <div
        style={{
          background: "#efefef",
        }}
      >
        <Container className="w-full">
          <div className="critique-view comments">
            <div className="container">
              <div className="row">
                <div className="col-md-10">
                  <div
                    className="split_button"
                    data-toggle="tooltip"
                    title=""
                    data-original-title="Split View"
                  ></div>
                  <h2>Overall Feedback</h2>
                  <form action="." method="post" id="critique_form">
                    <input
                      type="hidden"
                      name="csrfmiddlewaretoken"
                      value="59OUfmZ28sVu9qeogULXKx8VZ6Slogb05ydqwEp8N16dpgl8edRNzPR6X8FB8XCI"
                    />
                    <input
                      type="hidden"
                      name="status"
                      value="1"
                      id="id_status"
                    />

                    <textarea
                      name="contents"
                      cols={40}
                      rows={10}
                      id="id_contents"
                      style={{ visibility: "hidden", display: "none" }}
                    ></textarea>
                    <div
                      id="cke_id_contents"
                      className="cke_1 cke cke_reset cke_chrome cke_editor_id_contents cke_ltr cke_browser_webkit"
                      dir="ltr"
                      lang="es"
                      role="application"
                      aria-labelledby="cke_id_contents_arialbl"
                    >
                      <span
                        id="cke_id_contents_arialbl"
                        className="cke_voice_label"
                      >
                        Editor de Texto Enriquecido, id_contents
                      </span>
                      <div className="cke_inner cke_reset" role="presentation">
                        <span
                          id="cke_1_top"
                          className="cke_top cke_reset_all"
                          role="presentation"
                          style={{ height: "auto", userSelect: "none" }}
                        >
                          <span id="cke_5" className="cke_voice_label">
                            Barras de herramientas del editor
                          </span>
                          <span
                            id="cke_1_toolbox"
                            className="cke_toolbox"
                            role="group"
                            aria-labelledby="cke_5"
                            onMouseDown={(e) => e.preventDefault()}
                          >
                            <span
                              id="cke_6"
                              className="cke_toolbar cke_toolbar_last"
                              aria-labelledby="cke_6_label"
                              role="toolbar"
                            >
                              <span
                                id="cke_6_label"
                                className="cke_voice_label"
                              >
                                Enlaces
                              </span>
                              <span className="cke_toolbar_start"></span>
                              <span
                                className="cke_toolgroup"
                                role="presentation"
                              >
                                <a
                                  id="cke_7"
                                  className="cke_button cke_button__emojipanel cke_button_off cke_button_expandable"
                                  href="javascript:void('Emoji List')"
                                  title="Emoji List"
                                  tabIndex={-1}
                                  role="button"
                                  aria-labelledby="cke_7_label"
                                  aria-describedby="cke_7_description"
                                  aria-haspopup="listbox"
                                  aria-disabled="false"
                                  onKeyDown={() => {}}
                                  onFocus={() => {}}
                                  aria-expanded="false"
                                >
                                  <span
                                    className="cke_button_icon cke_button__emojipanel_icon"
                                    style={{ backgroundImage: "none" }}
                                  >
                                    &nbsp;
                                  </span>
                                  <span
                                    id="cke_7_label"
                                    className="cke_button_label cke_button__emojipanel_label"
                                    aria-hidden="false"
                                  >
                                    emoji
                                  </span>
                                  <span
                                    id="cke_7_description"
                                    className="cke_button_label"
                                    aria-hidden="false"
                                  ></span>
                                  <span className="cke_button_arrow"></span>
                                </a>
                              </span>
                              <span className="cke_toolbar_end"></span>
                            </span>
                          </span>
                        </span>
                        <div
                          id="cke_1_contents"
                          className="cke_contents cke_reset"
                          role="presentation"
                          style={{ height: "250px" }}
                        >
                          <iframe
                            src=""
                            className="cke_wysiwyg_frame cke_reset"
                            title="Editor de Texto Enriquecido, id_contents"
                            tabIndex={0}
                            style={{ width: "100%", height: "100%" }}
                          ></iframe>
                        </div>
                      </div>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <br />
                      <div className="row-fluid" style={{ marginTop: "-20px" }}>
                        <span className="pull-right text-right" id="word-count">
                          0 words (min. 5)
                        </span>
                      </div>

                      <div className="row-fluid">
                        <div className="col-md-3">
                          <button
                            id="finish_button"
                            className="btn btn-large green"
                            disabled={false}
                          >
                            Submit critique
                          </button>
                        </div>
                        <div className="col-md-3">
                          <button id="save_button" className="purple-link">
                            Save &amp; Finish Later
                          </button>
                        </div>
                        <div className="col-md-6 sm-text">
                          All comments save automatically. Submitting makes your
                          comments visible to the group. You may edit your
                          critique once submitted.
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}
