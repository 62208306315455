import React from 'react';
import { Link } from 'react-router-dom';

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { FormattedMessage } from "react-intl";

import Config from "../../config";
import WorkshopDropdown from './EventsDropdown';


const HeaderAnonymousUser = () => {
    return (
        <>
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link href={Config.BASE_API_URL + "inkon/"}>
                        <FormattedMessage id="header.ink_on" defaultMessage={"INK ON"} />
                    </Nav.Link>
                    <Nav.Link href={Config.BASE_API_URL + "matching-with-a-writing-group/"}>
                        <FormattedMessage id="header.find_a_group" defaultMessage={"FIND A GROUP"} />
                    </Nav.Link>

                    <WorkshopDropdown />

                    <Nav.Link href={Config.BASE_API_URL + "pricing/"}>
                        <FormattedMessage id="header.pricing" defaultMessage={"PRICING"} />
                    </Nav.Link>
                </Nav>
                <span className="spacer" />
                <Nav className="justify-content-end">
                    <a href={Config.BASE_API_URL + "account/register/"} className="btn yellow">Sign Up</a>
                    <Link to="/login/" className="btn purple">Log In</Link>
                </Nav>
            </Navbar.Collapse>
        </>
    );
}

export default HeaderAnonymousUser;