import React, { useEffect } from "react";
import "./Home.scss";
import Config from "config";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const Home = () => {
  const backend_url = Config.BASE_API_URL;
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  // const redirectTo = backend_url;
  // useEffect(() => {
  //   window.location.href = redirectTo;
  // }, []);

  return (
    <>
      <Container className="home">
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <h1 className="header">Writers rise together.</h1>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 12 }}>
            <h1 className="sub-title">
              Community, creative inspiration, and professional development for
              writers
            </h1>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <h4>
              We are tinkerers and noodlers, story shapers and word pickers,
              plotters, poets and pantsters. We find joy in the telling,
              excitement in the process, and pleasure in connecting with
              readers. We're committed to doing the work, even though often it
              doesn't come easily.
            </h4>
            <p>
              Inked Voices is a community for those who want to dig deep into
              their craft and process alongside fellow writers--but who like the
              flexibility of an online format.
            </p>
          </Col>
          <Col md={1} className="home-bird-container">
            <img
              className="home-bird"
              src={backend_url + "static/img/bird.png"}
              alt="Inked Voices - Share your voice, exchange feedback and become a better writer."
            />
          </Col>
          <Col md={5}>
            <img
              className="home-birds"
              src={backend_url + "static/img/birdsIndex.png"}
              alt="Inked Voices - Share your voice, exchange feedback and become a better writer."
            />
          </Col>
        </Row>
        <Row>
          <h2 className="separator">What you'll find here</h2>
        </Row>
        <Row>
          <Col md={6} className="card-container">
            <img
              className="img-writing-groups"
              src={backend_url + "static/img/community-final.png"}
              alt="Inked Voices - Share your voice, exchange feedback and become a better writer."
            />
            <h1>Writing Groups</h1>
            <p>
              Connect with like-minded writers in critique groups or
              accountability-only groups. Search for groups, join short-term
              critique "pop-ups" to meet others, and get suggestions from our
              community leads. Start a new group or host your existing group on
              our platform.
            </p>
            <p className="card-footer-text">
              We work with writers individually to help them find partners and
              groups.
            </p>
          </Col>
          <Col md={6} className="card-container">
            <img
              className="img-workshops"
              src={backend_url + "static/img/desktop-drawing.png"}
            />
            <h1>Workshops with Pros</h1>
            <p>
              Get professional feedback from authors, agents, and editors
              through first pages and multi-month classes hosted on our
              platform. Our small-group workshops blend written peer and
              professional feedback with live discussion.
            </p>
            <p className="card-footer-text">
              Members receive priority registration & discounted rates.
            </p>
          </Col>
        </Row>
        <Row className="what-youll-find-container">
          <Col md={6} className="card-container">
            <img
              className="img-laptop-drawing"
              src={backend_url + "static/img/laptop-drawing.png"}
            />
            <h1>Webinar Series</h1>
            <p>
              Join monthly webinars, panels, and conversations. Access our
              archive of more than 90 past talks, which you can watch on your
              schedule. Learn from poets and grammarians, mystery and SFF
              authors, and picture book pros.
            </p>
            <p className="card-footer-text">
              Our webinar series is free for members.
            </p>
          </Col>
          <Col md={6} className="card-container">
            <img
              className="img-community-initiatives"
              src={backend_url + "static/img/critique-group-working.png"}
            />
            <h1>Community Initiatives</h1>
            <p>
              Join book studies, writing challenges, or write-ins--all started
              and led by members. Share resources and connect in genre-specific
              discussion groups.
            </p>
            <p className="card-footer-text">
              Create the group you'd want to join.
            </p>
          </Col>
        </Row>
        <Row>
          <h2 className="separator">What's it like being an IV member?</h2>
        </Row>
        <Row className="what-it-like-container">
          <Col xs={12} md={8}>
            <p>
              <strong>
                Our members include rising writers and published authors{" "}
              </strong>
              from across the U.S. and around the globe. Our members primarily
              write fiction, narrative nonfiction and poetry, for both adults
              and children.
            </p>
            <p>
              <strong>
                Each member curates their own experience, but Inked Voices is
                also on hand to help.{" "}
              </strong>
              We love to know what you are working on, where you are with your
              writing and what you long for as a writer. We might make an
              introduction to a promising critique partner, suggest a group or
              "pop-up", or recommend a webinar we think you'd love.
            </p>
            <p>
              <strong>
                Our members use Inked Voices in a variety of ways.{" "}
              </strong>
              Exchange feedback with peers, join community discussion groups,
              attend webinars, or participate in professional workshops. Do it
              all or pick and choose.
            </p>
            <p>
              <strong>
                The people who get the most out of Inked Voices are those who
                participate.{" "}
              </strong>
              As you invest your time and energy in fellow writers, you'll find
              the same coming your way. Interested in starting a book club, a
              prompts group or a write-in? Members are encouraged to dream up
              new ideas.
            </p>
            <p>
              <strong>
                Over time, you'll build community, which is what we hope you'll
                find most of all.
              </strong>
            </p>
          </Col>
          <Col xs={12} md={4} className="testimonial-container">
            <Row className="testimonial">
              <Col xs={12} className="testimonial-sample text-center">
                <div className="quotation-mark">&ldquo;</div>
                <div className="testimonial-sample-text">
                  In Inked Voices, I have found a space that has galvanized my
                  fastest writing growth ever.&rdquo;
                  <Row>
                    <Col xs={6} md={12} className="testimonial-signature">
                      &mdash;Kim Ryall Woolcock
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row className="testimonial">
              <Col xs={12} className="testimonial-sample text-center">
                <div className="quotation-mark">&ldquo;</div>
                <div className="testimonial-sample-text">
                  I’m grateful for Inked Voices — as a community for writers; as
                  a space for studying, practicing, and sharing the joys and the
                  struggles that come with the writing journey.&rdquo;
                  <Row>
                    <Col xs={6} md={12} className="testimonial-signature">
                      &mdash;Patricia Franz
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <h2 className="separator">Learn more about us</h2>
        </Row>
        <Row>
          <Col md={4}>
            <Row className="learn-more-img">
              <Link to="/team" className="home-bird" onClick={scrollToTop}>
                <img
                  className="home-bird"
                  src={backend_url + "static/img/meet-our-team.png"}
                  alt="Meet our team"
                />
              </Link>
              <Link to="/team" onClick={scrollToTop}>
                <h4>Meet our team</h4>
              </Link>
            </Row>
            <Row className="learn-more-img">
              <img
                className="home-bird"
                src={backend_url + "static/img/how-does-a-group.png"}
                alt="How does a group workspace work?"
              />
              <h4>How does a group workspace work?</h4>
            </Row>
          </Col>
          <Col md={4}>
            <Row className="learn-more-img">
              <img
                className="home-bird"
                src={backend_url + "static/img/what-kinds.png"}
                alt="What kinds of groups are hosted here?"
              />
              <h4>What kinds of groups are hosted here?</h4>
            </Row>
            <Row className="learn-more-img">
              <img
                className="home-bird"
                src={backend_url + "static/img/i-already-have.png"}
                alt="I already have a writing group/I run a writing workshop. Can I host it here?"
              />
              <h4>
                I already have a writing group/I run a writing workshop. Can I
                host it here?
              </h4>
            </Row>
          </Col>
          <Col md={4}>
            <Row className="learn-more-img">
              <img
                className="home-bird"
                src={backend_url + "static/img/events-calendar.png"}
                alt="Events calendar."
              />
              <h4>Events calendar</h4>
            </Row>
            <Row className="learn-more-img">
              <img
                className="home-bird"
                src={backend_url + "static/img/whats-included.png"}
                alt="What’s included in membership?"
              />
              <h4>What’s included in membership?</h4>
            </Row>
          </Col>
        </Row>
        <Row className="testimonial">
          <Col xs={12} className="testimonial-sample text-center">
            <div className="quotation-mark">&ldquo;</div>
            <div className="testimonial-sample-text">
              What does Inked Voices have that so many other communities lack?
              For one thing, its size makes it possible to feel like a small
              town with a big reach, meaning tons of diversity in membership and
              offerings. For the first time in my life, I found myself in a
              critique group with writers of similar levels of professionalism
              and accomplishment. Inked Voices also offers workshops about
              agents and author platforms and all of that non-writing stuff that
              used to terrify me. Perhaps the best part of Inked Voices is the
              openness and positive vibe in the community.&rdquo;
              <Row>
                <Col
                  xs={6}
                  md={{ span: 6, offset: 3 }}
                  className="text-center testimonial-signature"
                >
                  &mdash;Gail Marlene Schwartz.
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Home;
