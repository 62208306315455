import config from "config";
import { BaseUser } from "types";
import { marginViewer } from "./annotator-margin-viewer";

export function initAnnotator(
  user: BaseUser,
  submission_id: number,
  element: HTMLDivElement,
  token: string
) {
  const Annotator = require("annotator");
  var app = new Annotator.App();
  app.include(Annotator.ui.main, { element: element });

  app.include(Annotator.storage.http, {
    prefix: `${config.BASE_API_URL}api/v1.0/note/`,
    urls: {
      create: `${submission_id}/${user.id}/Submission/note/`,
      read: `${submission_id}/${user.id}/Submission/note/:id`,
      update: `{id}`,
      destroy: `{id}`,
      search: `${submission_id}/${user.id}/Submission/note/`,
    },
    headers: {
      Authorization: `Token ${token}`,
    },
  });

  app.include(marginViewer, {
    user: { ...user, can_delete: true },
    user_list: [user],
  });

  app.start().then(function () {
    app.annotations.load();
  });
}
