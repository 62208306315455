import { Annotation } from "types";
import ReactDOMServer from "react-dom/server";
import $ from "jquery";

const DATA_HIGHLIGHT_STARTS = "data-highlight-starts";
const DATA_HIGHLIGHT_ENDS = "data-highlight-ends";

interface FullAnnotation extends Annotation {
  _marginObject: HTMLElement | null;
  _local: {
    highlights: HTMLSpanElement[];
  };
  _marginindex: number;
}

type MarginViewerOptions = {
  user: {
    id: number;
    pen_name: string;
    can_delete: boolean;
  };
  shortenEnabled: boolean;
  user_list: {
    id: number;
    pen_name: string;
  }[];
};

type Location = {
  top: number;
  left: number;
};

export function marginViewer(options: MarginViewerOptions) {
  const displacement = -27;
  let data: { location: Location; annotation: FullAnnotation }[] = [];
  let insertions = 0;
  let deletions = 0;
  let annotations: Record<string, FullAnnotation> = {};
  let currentSelectedAnnotation: FullAnnotation | null = null;

  const sign = function (x: number) {
    if (x === 0) {
      return 0;
    } else {
      return x / Math.abs(x);
    }
  };

  const objectEquals = function (obj1: FullAnnotation, obj2: FullAnnotation) {
    if ("id" in obj1 && "id" in obj2) {
      return obj1.id === obj2.id;
    }
    if ("id" in obj1 || "id" in obj2) {
      return false;
    }
    if ("_marginindex" in obj1 && "_marginindex" in obj2) {
      return obj1["_marginindex"] === obj2["_marginindex"];
    }
    return false;
  };

  const getObjectLocation = function (annotation: FullAnnotation) {
    var _i;
    const supposedLocation = annotation._marginindex;
    if (
      supposedLocation < data.length &&
      objectEquals(data[supposedLocation].annotation, annotation)
    ) {
      return supposedLocation;
    }
    const minimumIndex = Math.max(0, supposedLocation - deletions);
    const maximumIndex = Math.min(
      data.length - 1,
      supposedLocation + insertions
    );
    for (
      let index = (_i = minimumIndex);
      minimumIndex <= maximumIndex ? _i <= maximumIndex : _i >= maximumIndex;
      index = minimumIndex <= maximumIndex ? ++_i : --_i
    ) {
      if (typeof data[index] == "undefined") {
        return -1;
      }
      const currentObjectAnnotation = data[index].annotation;
      if (objectEquals(currentObjectAnnotation, annotation)) {
        currentObjectAnnotation._marginindex = index;
        return index;
      }
    }
    return -1;
  };

  const getNewLocationsForObject = function (
    top: number,
    bottom: number,
    obj: HTMLElement
  ) {
    const annotation =
      annotations[obj.getAttribute("data-annotation-id") || ""];
    const objectIndex = getObjectLocation(annotation);
    let currentIndex = objectIndex - 1;
    let currentNewTop = top;
    let currentNewBottom = bottom;
    let locationChanges: [number, HTMLElement][] = [];
    let objectNewTop = top;
    while (currentIndex >= 0) {
      const currentObject = data[currentIndex].annotation
        ._marginObject as HTMLElement;
      const currentObjectAnnotation =
        annotations[currentObject.getAttribute("data-annotation-id") || ""];
      const currentObjectSize = currentObject.clientHeight;
      const currentObjectTop = currentObject.offsetTop;
      const currentObjectBottom = currentObjectTop + currentObjectSize;
      const currentObjectGoalLocation = paramFuncObject.sortDataMap(
        currentObjectAnnotation
      );
      const currentObjectGoalBottom =
        currentObjectGoalLocation.top + currentObjectSize;
      if (currentObjectBottom > currentNewTop) {
        const objectNewTop = currentNewTop - currentObjectSize;
        locationChanges.push([objectNewTop, currentObject]);
        currentNewTop = objectNewTop;
      } else {
        if (currentObjectGoalLocation.top > currentObjectTop) {
          if (currentObjectGoalBottom < currentNewTop) {
            objectNewTop = currentObjectGoalLocation.top;
            locationChanges.push([objectNewTop, currentObject]);
            currentNewTop = currentObjectGoalLocation.top;
          } else {
            objectNewTop = currentNewTop - currentObjectSize;
            locationChanges.push([objectNewTop, currentObject]);
            currentNewTop = objectNewTop;
          }
        } else {
          break;
        }
      }
      currentIndex -= 1;
    }
    currentIndex = objectIndex + 1;
    while (currentIndex < data.length) {
      const currentObject = data[currentIndex].annotation
        ._marginObject as HTMLElement;
      const currentObjectAnnotation =
        annotations[currentObject.getAttribute("data-annotation-id") || ""];
      const currentObjectSize = currentObject.clientHeight;
      const currentObjectTop = currentObject.offsetTop;
      const currentObjectBottom = currentObjectTop + currentObjectSize;
      const currentObjectGoalLocation = paramFuncObject.sortDataMap(
        currentObjectAnnotation
      );
      const currentObjectGoalBottom =
        currentObjectGoalLocation.top + currentObjectSize;
      if (currentObjectTop < currentNewBottom) {
        objectNewTop = currentNewBottom;
        locationChanges.push([objectNewTop, currentObject]);
        currentNewBottom = objectNewTop + currentObjectSize;
      } else {
        if (currentObjectGoalLocation.top < currentObjectTop) {
          if (currentObjectGoalLocation.top > currentNewBottom) {
            objectNewTop = currentObjectGoalLocation.top;
            locationChanges.push([objectNewTop, currentObject]);
            currentNewBottom = objectNewTop + currentObjectSize;
          } else {
            objectNewTop = currentNewBottom;
            locationChanges.push([objectNewTop, currentObject]);
            currentNewBottom = objectNewTop + currentObjectSize;
          }
        } else {
          break;
        }
      }
      currentIndex += 1;
    }
    return locationChanges;
  };

  const paramFuncObject = {
    sortDataMap: function (annotation: FullAnnotation) {
      let dbg: Location;
      const highlight = annotation._local.highlights[0];
      const offset = highlight
        ? {
            top: highlight.offsetTop,
            left: highlight.offsetLeft,
          }
        : null;
      if (offset === null) {
        dbg = {
          top: 0,
          left: 0,
        };
      } else {
        dbg = {
          top: offset.top + displacement,
          left: offset.left,
        };
      }

      return dbg;
    },
    sortComparison: function (left: Location, right: Location) {
      if (sign(left.top - right.top) == 0) {
        return sign(left.left - right.left);
      } else {
        return sign(sign(left.top - right.top));
      }
      //        return sign(sign(left.top - right.top) * 2 + sign(left.left - right.left) * RTL_MULT);
    },
    idFunction: function (annotation: FullAnnotation) {
      return annotation.id;
    },
    sizeFunction: function (element: HTMLSpanElement) {
      // return element.outerHeight(true);
      debugger;
      return element.clientHeight;
    },
  };

  const funcObject = {
    mapFunc: (annotation: FullAnnotation) => {
      return {
        location: paramFuncObject.sortDataMap(annotation),
        annotation: annotation,
      };
    },
  };

  function findIndexForNewObject(location: Location) {
    var currentIndex, endIndex, startIndex;
    startIndex = 0;
    endIndex = data.length;
    for (
      currentIndex = startIndex;
      currentIndex < endIndex;
      currentIndex += 1
    ) {
      if (
        paramFuncObject.sortComparison(location, data[currentIndex].location) <
        0
      ) {
        break;
      }
    }
    return currentIndex;
  }

  const overlappedObjects = function (marginObject: HTMLElement) {
    // var marginObject = obj.closest(".annotator-marginviewer-element");
    var all = document
      .querySelector(".secondary")
      ?.querySelectorAll(".annotator-marginviewer-element");

    const starts = parseFloat(
      marginObject?.getAttribute(DATA_HIGHLIGHT_STARTS) || "0"
    );
    const ends = parseFloat(
      marginObject?.getAttribute(DATA_HIGHLIGHT_ENDS) || "0"
    );

    var overppedObjects: HTMLElement[] = [];
    all?.forEach((otherObject) => {
      const otherStarts = parseFloat(
        otherObject.getAttribute(DATA_HIGHLIGHT_STARTS) || "0"
      );
      const otherEnds = parseFloat(
        otherObject.getAttribute(DATA_HIGHLIGHT_ENDS) || "0"
      );
      if (
        otherObject != marginObject &&
        ((otherStarts <= starts && otherEnds >= starts) ||
          (otherStarts <= ends && otherEnds >= ends))
      ) {
        overppedObjects.push(otherObject as HTMLElement);
      }
    });
    return overppedObjects;
  };

  function addNewObject(annotation: FullAnnotation, location: Location) {
    const newObjectIndex = findIndexForNewObject(location);
    data.splice(newObjectIndex, 0, funcObject.mapFunc(annotation));
    annotation._marginindex = newObjectIndex;
    return (insertions += 1);
  }

  // create an object with a color for each user id in options.user_list
  let colorDict: Record<number, string> = {};
  options.user_list.forEach((user, index) => {
    colorDict[user.id] = (index + 1).toString();
  });

  const secondaryMarginDiv = document.getElementsByClassName("secondary")[0];

  function renderMarginObject(
    annotation: FullAnnotation,
    location: number | undefined,
    hide: boolean
  ): JSX.Element {
    const datetime = annotation.created ? annotation.created : "";
    const user = options.user;
    const delel =
      user && (annotation.user.id == user.id || user.can_delete) ? (
        <>
          <span
            className="annotator-marginviewer-delete"
            style={{ float: "right", direction: "ltr" }}
            onClick={(event) => onMarginDeleted(event.target)}
          ></span>
          <span
            className="annotator-marginviewer-edit"
            style={{ float: "right", direction: "ltr" }}
            onClick={(event) => onMarginEdited(event.target)}
          ></span>
        </>
      ) : (
        <></>
      );
    const color_index =
      annotation.user && typeof colorDict != undefined
        ? colorDict[annotation.user.id]
        : "1";
    const colored_css_class = "annotator-hl-color-" + color_index;
    const highlight = annotation._local.highlights[0];
    const style = {
      top: location,
      display: highlight && !hide ? "block" : "none",
    };
    if (highlight) {
      annotation._local.highlights[0].classList.add(colored_css_class);
    }

    const text = options.shortenEnabled
      ? annotation.text?.substring(0, 120)
      : annotation.text;

    return (
      <div className="annotator-marginviewer-element" style={style}>
        <div className="arrow"></div>
        <div
          className={`annotator-marginviewer-color annotator-marginviewer-color-${color_index}`}
        ></div>
        <div className="annotator-marginviewer-text-wrapper">
          {delel}
          <div className="annotator-marginviewer-text">{text}</div>
        </div>
        <div className="annotator-marginviewer-header">
          <span
            className="annotator-marginviewer-date"
            style={{ float: "right", direction: "ltr" }}
          >
            {datetime}
          </span>
          <span className="annotator-marginviewer-user">
            {annotation.user.pen_name}
          </span>
        </div>
      </div>
    );
  }
  const updateObjectLocation = function (annotation: FullAnnotation) {
    const objIndex = getObjectLocation(annotation);
    data[objIndex] = {
      location: paramFuncObject.sortDataMap(annotation),
      annotation: annotation,
    };
    return (annotation._marginindex = objIndex);
  };

  const moveObjectsToNewLocation = (
    newLocations: [number, HTMLElement][],
    horizontalSlideObjects: [number, string, HTMLElement][]
  ) => {
    var currentObject,
      horizontalSlide,
      newLocationStructure,
      newMarginRight,
      newTop,
      _i,
      _j,
      _len,
      _len1,
      _results;
    if (horizontalSlideObjects == null) {
      horizontalSlideObjects = [];
    }
    for (_i = 0, _len = newLocations.length; _i < _len; _i++) {
      newLocationStructure = newLocations[_i];
      newTop = newLocationStructure[0];
      currentObject = newLocationStructure[1];
      currentObject.animate(
        {
          top: `${newTop - currentObject.offsetTop}px`,
        },
        {
          duration: 800,
          easing: "ease-in",
        }
      );
      currentObject.style["top"] = `${newTop - currentObject.offsetTop}px`;
      const annotation =
        annotations[currentObject.getAttribute("data-annotation-id") || -1];
      updateObjectLocation(annotation);
    }
    _results = [];
    for (_j = 0, _len1 = horizontalSlideObjects.length; _j < _len1; _j++) {
      horizontalSlide = horizontalSlideObjects[_j];
      newTop = horizontalSlide[0];
      newMarginRight = horizontalSlide[1];
      currentObject = horizontalSlide[2];
      currentObject.animate(
        {
          top: `${newTop - currentObject.offsetTop}px`,
          "margin-right": `${newMarginRight}px`,
        },
        {
          duration: 800,
          easing: "ease-in",
        }
      );
      currentObject.style["top"] = `${newTop - currentObject.offsetTop}px`;
      const annotation =
        annotations[currentObject.getAttribute("data-annotation-id") || -1];
      _results.push(updateObjectLocation(annotation));
    }
    return _results;
  };

  function onMarginEdited(marginObject: EventTarget | null) {
    return marginObject;
  }

  function onMarginDeleted(marginObject: EventTarget | null) {
    return marginObject;
  }

  function onMarginSelected(obj: HTMLElement | null) {
    debugger;
    const marginObject = obj?.closest(
      ".annotator-marginviewer-element"
    ) as HTMLElement;
    const color = marginObject?.getAttribute("data-color");
    const annotationId = marginObject?.getAttribute("data-annotation-id");
    const annotation = annotations[annotationId || 0];
    let horizontalSlide: [number, string, HTMLElement][] = [];
    const highlight = annotation._local.highlights[0];
    const offset = {
      top: highlight.offsetTop,
      left: highlight.offsetLeft,
    };
    const newTop = offset.top + displacement;
    const newBottom = marginObject.clientHeight + newTop;
    let newLocationsByObject = getNewLocationsForObject(
      newTop,
      newBottom,
      marginObject
    );
    let overlapped: HTMLElement[] = [];
    if (currentSelectedAnnotation !== null) {
      if (annotation.id === currentSelectedAnnotation.id) {
        return;
      } else {
        const currentMarginObject = currentSelectedAnnotation._marginObject;
        if (currentMarginObject !== null) {
          var cur_color = currentMarginObject.getAttribute("data-color");
          currentSelectedAnnotation._local.highlights.forEach(function (
            highlight: HTMLElement
          ) {
            highlight.classList.remove("annotator-hl-uber");
            highlight.classList.remove("annotator-hl-uber-color-" + cur_color);
            highlight.classList.remove(
              "annotator-hl-uber-temp-color-" + cur_color
            );
            highlight.classList.remove("annotator-hl-uber-temp");
            highlight.classList.add("annotator-hl");
            highlight.classList.add("annotator-hl-color-" + cur_color);
          });
          const filteredClasses = highlight.className
            .split(" ")
            .filter(function (className) {
              // exclude if it's in a list of classes
              return (
                [
                  "annotator-hl-uber",
                  "annotator-hl-uber-color-" + cur_color,
                  "annotator-hl-uber-temp-color-" + cur_color,
                  "annotator-hl-uber-temp",
                ].indexOf(className) === -1
              );
            });
          highlight.className =
            filteredClasses.join(" ") +
            "annotator-hl annotator-hl-color-" +
            cur_color;
          overlapped = overlappedObjects(currentMarginObject);
          overlapped.forEach(function (overlappedObject: HTMLElement) {
            overlappedObject.classList.remove(
              "annotator-marginviewer-semi-selected"
            );
          });
          let currentObjectNewTop = currentMarginObject.offsetTop;
          newLocationsByObject = newLocationsByObject.filter((value) => {
            const currentMarginObjectAnnotationId =
              currentMarginObject.getAttribute("data-annotation-id") || 0;
            const otherAnnotation =
              annotations[value[1].getAttribute("data-annotation-id") || 0];
            if (otherAnnotation.id === currentMarginObjectAnnotationId) {
              currentObjectNewTop = value[0];
              return false;
            } else {
              return true;
            }
          });
          horizontalSlide.push([
            currentObjectNewTop,
            "+=20px",
            currentMarginObject,
          ]);
          currentMarginObject.classList.remove(
            "annotator-marginviewer-selected"
          );
          currentMarginObject.classList.remove(
            "annotator-marginviewer-semi-selected"
          );
        }
      }
    }
    horizontalSlide.push([newTop, "-=10px", marginObject]);
    moveObjectsToNewLocation(newLocationsByObject, horizontalSlide);
    annotation._local.highlights.forEach(function (highlight: HTMLElement) {
      highlight.classList.add("annotator-hl-uber");
      highlight.classList.add("annotator-hl-uber-color-" + color);
      highlight.classList.remove("annotator-hl");
      highlight.classList.remove("annotator-hl-color-" + color);
    });
    marginObject.classList.add("annotator-marginviewer-selected");
    marginObject.classList.add("annotator-marginviewer-semi-selected");
    overlapped.forEach(function (overlappedObject: HTMLElement) {
      overlappedObject.classList.add("annotator-marginviewer-semi-selected");
    });
    return (currentSelectedAnnotation = annotation);
  }

  const getMarginObjects = function () {
    return data.map(function (x) {
      return x.annotation._marginObject;
    });
  };

  function onMarginMouseIn(marginObject: EventTarget | null) {
    return marginObject;
  }

  function onMarginMouseOut(marginObject: EventTarget | null) {
    return marginObject;
  }

  function createMarginObject(
    annotation: FullAnnotation,
    location?: number,
    hide?: boolean
  ) {
    const newMarginObject = renderMarginObject(annotation, location, !!hide);
    secondaryMarginDiv.innerHTML +=
      ReactDOMServer.renderToString(newMarginObject);
    const marginObject: HTMLElement =
      secondaryMarginDiv.lastChild as HTMLElement;

    document.addEventListener("click", (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const closest = target.closest(".annotator-marginviewer-element");
      if (
        closest?.getAttribute("data-annotation-id") ===
        marginObject.getAttribute("data-annotation-id")
      ) {
        onMarginSelected(marginObject);
      }
    });
    marginObject.addEventListener("mouseenter", (event) => {
      const target = event.target as HTMLElement;
      const closest = target.closest(".annotator-marginviewer-element");
      if (
        closest?.getAttribute("data-annotation-id") ===
        marginObject.getAttribute("data-annotation-id")
      ) {
        onMarginMouseIn(marginObject);
      }
    });
    marginObject.addEventListener("mouseleave", (event) => {
      const target = event.target as HTMLElement;
      const closest = target.closest(".annotator-marginviewer-element");
      if (
        closest?.getAttribute("data-annotation-id") ===
        marginObject.getAttribute("data-annotation-id")
      ) {
        onMarginMouseOut(marginObject);
      }
    });
    annotation._marginObject = marginObject;
    // marginObject.annotation = annotation;

    const highlight = annotation._local.highlights[0];
    const offsetTop = highlight ? highlight.offsetTop : 0;
    // set data attributes
    const starts = offsetTop + displacement;
    const height = highlight ? highlight.offsetHeight : 0;
    const ends = starts + height;
    annotations[annotation.id.toString()] = annotation;
    const color_index =
      annotation.user && typeof colorDict != undefined
        ? colorDict[annotation.user.id]
        : "1";
    marginObject?.setAttribute(DATA_HIGHLIGHT_STARTS, starts.toString());
    marginObject?.setAttribute("data-highlight-height", height.toString());
    marginObject?.setAttribute(DATA_HIGHLIGHT_ENDS, ends.toString());
    marginObject?.setAttribute("data-annotation-id", annotation.id.toString());
    marginObject?.setAttribute("data-color", color_index.toString());
    return marginObject;
  }

  return {
    annotationCreated: function (annotation: FullAnnotation) {
      var currentdate = new Date();
      annotation.created =
        ("0" + (currentdate.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + currentdate.getDate()).slice(-2) +
        "-" +
        ("" + currentdate.getFullYear()).slice(2);
      // todo: revert this to false
      const marginObject = createMarginObject(annotation, undefined, false);

      const highlight = annotation._local.highlights[0];
      const offsetTop = (highlight ? highlight.offsetTop : 0) + displacement;
      const offsetLeft = highlight ? highlight.offsetLeft : 0;
      // const newObjectBottom = newObjectTop + marginObject.outerHeight(true);
      addNewObject(annotation, { top: offsetTop, left: offsetLeft });
      // marginObject.fadeIn("fast");

      return onMarginSelected(marginObject);
    },
    annotationsLoaded: function (annotations: FullAnnotation[]) {
      if (annotations.length == 0) {
        return [];
      }
      const sortedAnnotations = annotations.sort(function (
        a: FullAnnotation,
        b: FullAnnotation
      ) {
        return (
          a._local.highlights[0].offsetTop - b._local.highlights[0].offsetTop
        );
      });
      data = sortedAnnotations.map(function (annotation: FullAnnotation) {
        return {
          annotation: annotation,
          location: paramFuncObject.sortDataMap(annotation),
        };
      });
      let currentLocation = 0;
      let _results: number[] = [];
      const wrapper = document.getElementsByClassName(
        "secondary margin-annotator-container"
      )[0] as HTMLElement;
      const wrapperOffset = wrapper.offsetTop;
      let addedHeights = 0;

      sortedAnnotations.forEach(function (annotation: FullAnnotation) {
        const highlight = annotation._local.highlights[0];
        const offsetTop = $(highlight)?.offset()?.top || 0;
        let newLocation =
          offsetTop + displacement - wrapperOffset - addedHeights;
        if (currentLocation > newLocation) {
          newLocation = currentLocation;
        }
        const marginObject = createMarginObject(annotation, newLocation);
        updateObjectLocation(annotation);
        currentLocation = newLocation;
        addedHeights += marginObject.offsetHeight;
        _results.push(currentLocation);
      });
      return _results;
    },
  };
}
