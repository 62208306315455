import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./Footer.scss";
import { FormattedMessage } from "react-intl";
import facebook_logo from "../../assets/facebook-icon.png";
import twitter_logo from "../../assets/twitter-icon.png";
import { Button } from "react-bootstrap";
import Config from "../../config";
import { Link } from "react-router-dom";
const BASE_API_URL = Config.BASE_API_URL;

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <footer
      className="ink-footer bd-footer py-5 mt-5 text-white"
      id="iv_footer"
    >
      <div className="container fit-container">
        <div className="row">
          <div className="col-xs-12 col-sm-3">
            <p>
              <FormattedMessage id="footer.inkedvoicesPurpose" />
            </p>
          </div>
          <div className="col-xs-12 col-sm-6">
            <div className="col-xs-4 col-sm-4">
              <h4>
                <FormattedMessage id="footer.company" />
              </h4>
              <ul className="list-unstyled">
                <li className="mb-2">
                  <a
                    className="text-decoration-none link-light"
                    href={BASE_API_URL + "about/"}
                  >
                    <FormattedMessage id="footer.about" />
                  </a>
                </li>
                <li className="mb-2">
                  <Link
                    className="text-decoration-none link-light"
                    to="/team/"
                    onClick={scrollToTop}
                  >
                    <FormattedMessage id="footer.team" />
                  </Link>
                </li>
                <li className="mb-2">
                  <a
                    className="text-decoration-none link-light"
                    href={BASE_API_URL + "about/principles/"}
                  >
                    <FormattedMessage id="footer.guidingPrinciples" />
                  </a>
                </li>
                <li className="mb-2">
                  <Link
                    to="/about/press/"
                    className="text-decoration-none link-light"
                    onClick={scrollToTop}
                  >
                    <FormattedMessage id="footer.press" />
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    to="/work-with-us/"
                    className="text-decoration-none link-light"
                    onClick={scrollToTop}
                  >
                    <FormattedMessage id="footer.workWithUs" />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-xs-4 col-sm-4">
              <h4>
                <FormattedMessage id="footer.platform" />
              </h4>
              <ul className="list-unstyled">
                <li className="mb-2">
                  <a
                    className="text-decoration-none link-light"
                    href={BASE_API_URL + "pricing/"}
                  >
                    <FormattedMessage id="footer.pricing" />
                  </a>
                </li>
                <li className="mb-2">
                  <Link
                    to="/testimonials/"
                    className="text-decoration-none link-light"
                    onClick={scrollToTop}
                  >
                    <FormattedMessage id="footer.testimonials" />
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    to="/faq/"
                    className="text-decoration-none link-light"
                    onClick={scrollToTop}
                  >
                    <FormattedMessage id="footer.faq" />
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    to="/code-of-conduct/"
                    className="text-decoration-none link-light"
                    onClick={scrollToTop}
                  >
                    <FormattedMessage id="footer.codeofConduct" />
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    to="/educators-and-writing-programs/"
                    className="text-decoration-none link-light"
                    onClick={scrollToTop}
                  >
                    <FormattedMessage id="footer.forEducators" />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-xs-4 col-sm-4">
              <h4>
                <FormattedMessage id="footer.resources" />
              </h4>
              <ul className="list-unstyled">
                <li className="mb-2">
                  <a
                    className="text-decoration-none link-light"
                    href={BASE_API_URL + "inkon/"}
                  >
                    <FormattedMessage id="footer.inkOn" />
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    className="text-decoration-none link-light"
                    href={BASE_API_URL + "writing/"}
                  >
                    <FormattedMessage id="footer.writingGroups101" />
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    className="text-decoration-none link-light"
                    href={BASE_API_URL + "static/critique_sheet_long.pdf"}
                  >
                    <FormattedMessage id="footer.critiqueGuide" />
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    className="text-decoration-none link-light"
                    href={BASE_API_URL + "help/"}
                  >
                    <FormattedMessage id="footer.help" />
                  </a>
                </li>
                <li className="mb-2">
                  <img
                    alt="sponsor"
                    className="sponsor"
                    src={BASE_API_URL + "static/img/sponsor3.jpg?a0d8260f3c5b/"}
                  />
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xs-12 col-sm-3">
            <h4>
              <FormattedMessage id="footer.connect" />
            </h4>
            <ul className="list-unstyled">
              <li className="mb-2">
                <a className="text-decoration-none link-light" href="#">
                  <FormattedMessage id="footer.contactUs" />
                </a>
                <a href="https://www.facebook.com/InkedVoicesLLC">
                  <img
                    alt="facebook-logo"
                    src={facebook_logo}
                    className="social-media-icon"
                  />
                </a>
                <a href="https://twitter.com/inkedvoices">
                  <img
                    alt="twitter_logo"
                    src={twitter_logo}
                    className="social-media-icon"
                  />
                </a>
              </li>
              <li className="mb-2 newsletter-link">
                <FormattedMessage id="footer.signUpForOurNewsletter" />
                <form role="form" id="subscribe_form" className="form-inline">
                  <div className="form-group">
                    <input
                      placeholder="Email address"
                      className="form-control"
                    />
                    <Button className="yellow">JOIN</Button>
                  </div>
                </form>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div id="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <div id="footer-copyright" className="col-xs-8">
                © 2023 Inked Voices, LLC. All Rights Reserved.{" "}
                <span id="footer-enjoy">Enjoy the rest of your day!</span>
              </div>
              <div id="footer-terms" className="col-xs-4 pull-right">
                <Link
                  to="/terms-of-service/"
                  className="text-decoration-none link-light"
                  onClick={scrollToTop}
                >
                  <FormattedMessage id="footer.termsOfService" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
