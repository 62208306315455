import React, { useState, useContext, useEffect } from "react";
import useFetch from "custom-hooks/useFetch";
import { useHistory } from "react-router";
import "./Forum.scss";
import { IPostData } from "models/postData";
import { IUserData } from "models/user";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Nav,
  Row,
  Table,
} from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import AuthenticationContext from "contexts/AuthenticationContext";
import Loading from "components/loading/Loading";
import { Gear, Trash } from "react-bootstrap-icons";
import { loadposts, updateCurrentPost } from "redux/postsReducer";
import { useDispatch, useSelector } from "react-redux";

const Forum = (props: { data: Array<IPostData> }) => {
  const history = useHistory();
  const user = useSelector((state: any) => state.user);
  const posts = useSelector((state: any) => state.posts);
  const [postTitle, setPostTitle] = useState("");
  const [postSchedule, setPostSchedule] = useState("");
  const [description, setDescription] = useState("");
  const [formErrors, setFormErrors] = useState({ postTitle: "" });
  const [selectedRoom, setSelectedRoom] = useState<IPostData | null>(null);
  const [state, fetchData] = useFetch();
  const authenticationContext = useContext(AuthenticationContext);
  const dispatch = useDispatch();

  if (history.location.search != "") {
    history.replace(history.location.pathname);
  }

  const formPostTitle = (<>
    <Form.Control
      type="text"
      placeholder="Enter a title"
      onChange={(e) => setPostTitle(e.target.value)}
      value={postTitle}
    /></>
  );
  const formPostSchedule = (
    <Form.Control
      as="textarea"
      placeholder="Enter a schedule"
      aria-label="Enter a schedule"
      className="ink-forum__schedule"
      onChange={(e) => setPostSchedule(e.target.value)}
      value={postSchedule}
    />
  );
  const formDescription = (
    <Form.Control
      as="textarea"
      placeholder="Enter a description"
      aria-label="Enter a description"
      className="ink-forum__schedule"
      onChange={(e) => setDescription(e.target.value)}
      value={description}
    />
  );
  const form = (
    <Form>
      <Form.Group controlId="formPost">
        {formErrors.postTitle && <Alert variant="danger">
          {formErrors.postTitle}
        </Alert>}
        {formPostTitle}
        {formDescription}
        {formPostSchedule}
      </Form.Group>
    </Form>
  );

  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleSelect = () => {
    setShowModal(true);
  };
  const context = useContext(AuthenticationContext);

  let content = <Loading></Loading>;

  if (posts.loading) {
    content = <Loading></Loading>;
  }

  const validateForm = () => {
    if (postTitle == '') {
      setFormErrors({ postTitle: "Title is required." });
      return false
    }
    return true
  }

  const handleSubmit = () => {
    let url;
    let method;
    if (validateForm()) {
      if (selectedRoom) {
        url = "forum/posts/" + selectedRoom.id + "/";
        method = "PATCH";
      } else {
        url = "forum/posts/";
        method = "POST";
      }
      fetchData({
        url: url,
        method: method,
        token: context.token,
        body: JSON.stringify({
          name: postTitle,
          schedule: postSchedule,
          description: description,
        }),
      }).then((res) => {
        closeEditRoomModal();
        dispatch(loadposts(authenticationContext.token));
      });

      if (state.isSuccess) {
        closeEditRoomModal();
        dispatch(loadposts(authenticationContext.token));
      }
    }
  };

  const handleDoDelete = () => {
    let url;
    let method;
    url = "forum/posts/" + selectedRoom?.id + "/";
    method = "DELETE";
    fetchData({
      url: url,
      method: method,
      token: context.token,
    }).then((res) => {
      setShowDeleteModal(false);
      dispatch(loadposts(authenticationContext.token));
    });

    if (state.isSuccess) {
      setShowDeleteModal(false);
      dispatch(loadposts(authenticationContext.token));
    }
  }

  const handleItemClick = (item: IPostData) => {
    dispatch(updateCurrentPost(item));
    history.push("/forum/" + item.id);
  };

  const userImages = (users: IUserData[]) => {
    return users.map((item) => (
      <Image className="ink-forum__size" src={item.avatar} roundedCircle />
    ));
  };

  const openEditRoomModal = (item: IPostData) => {
    setPostTitle(item.name || "");
    setPostSchedule(item.schedule);
    setDescription(item.description);
    setSelectedRoom(item);
    setShowModal(true);
  };

  const closeEditRoomModal = () => {
    setPostTitle("");
    setPostSchedule("");
    setDescription("");
    setSelectedRoom(null);
    setShowModal(false);
    setFormErrors({ postTitle: "" });
  };

  const openDeleteRoomModal = (item: IPostData) => {
    setSelectedRoom(item);
    setShowDeleteModal(true);
  };

  if (!posts.loading) {
    let rows = posts.posts.map((item: IPostData) => (
      <tr
        key={item.id}
        onClick={() => handleItemClick(item)}
        className="ink-forum__row"
      >
        <td className="ink-forum__name">
          <a>{item.name}</a>&nbsp;
          {user.data.id == item.creator?.id && (<>
            <a onClick={(e) => {
              e.stopPropagation();
              openEditRoomModal(item);
            }}
            >
              <Gear />
            </a>&nbsp;
            <a onClick={(e) => {
              e.stopPropagation();
              openDeleteRoomModal(item);
            }}
            >
              <Trash />
            </a>
          </>
          )}
        </td>
        <td className="ink-forum__users">{item.creator?.pen_name}</td>
        <td className="ink-forum__updated-date">
          {new Intl.DateTimeFormat("en-GB", {
            year: "numeric",
            month: "long",
            day: "2-digit",
          }).format(new Date(item.updated_date))}
        </td>
      </tr>
    ));
    rows = [
      <tr key="header" className="ink-forum__row">
        <td className="ink-forum__name">
          <FormattedMessage id="postlist.table.roomName" />
        </td>
        <td className="ink-forum__users">
          <FormattedMessage id="postlist.table.organizer" />
        </td>
        <td className="ink-forum__updated-date">
          <FormattedMessage id="postlist.table.lastActivity" />
        </td>
      </tr>,
    ].concat(rows);

    content = (
      <>
        {/* EDIT MODAL */}
        <Modal show={showModal} onHide={closeEditRoomModal}>
          <Modal.Header>
            <Modal.Title>
              <FormattedMessage
                id={
                  selectedRoom
                    ? "postlist.modal.editPost"
                    : "postlist.modal.createNewPost"
                }
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {form}
            {state.isFailed && <div>Error</div>}
            {state.isLoading && <Loading></Loading>}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeEditRoomModal}>
              <FormattedMessage id="postlist.modal.close" />
            </Button>
            <Button
              variant="primary"
              className="ink-forum__button"
              disabled={state.isLoading}
              onClick={handleSubmit}
            >
              <FormattedMessage id="postlist.modal.save" />
            </Button>
          </Modal.Footer>
        </Modal>
        {/* DELETE MODAL */}
        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
          <Modal.Header>
            <Modal.Title>
              <FormattedMessage
                id={
                  selectedRoom
                    ? "postlist.modal.editPost"
                    : "postlist.modal.createNewPost"
                }
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete this group?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
              <FormattedMessage id="postlist.modal.close" />
            </Button>
            <Button
              variant="primary"
              className="ink-forum__button"
              disabled={state.isLoading}
              onClick={handleDoDelete}
            >
              <FormattedMessage id="postlist.modal.delete" />
            </Button>
          </Modal.Footer>
        </Modal>
        <Nav
          className="ink-forum__nav"
          activeKey="1"
          variant="pills"
          onSelect={handleSelect}
        >
          <Nav.Item>
            <Nav.Link eventKey="1">
              <FormattedMessage id="postlist.newPost" />
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Table mt-3>
          <tbody>{rows}</tbody>
        </Table>
      </>
    );
  }

  return (
    <Container className="ink-forum">
      <h1 className="ink-forum-item__title">Writing rooms</h1>
      <Row className="ink-forum__content">
        <Col md="auto">{content}</Col>
      </Row>
    </Container>
  );
};

export default Forum;
