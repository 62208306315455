import React, { useContext, useEffect, useState } from "react";
import Select, { MultiValue } from "react-select";
import CreatableSelect from "react-select/creatable";

import AuthenticationContext from "contexts/AuthenticationContext";
import useFetch from "custom-hooks/useFetch";
import { Controller } from "react-hook-form";

export interface MultiSelectOption {
  label: string;
  value: string;
}
interface SelectProps {
  name: string;
  options: any[];
  control: any;
  isCreatable?: boolean;
  entityType?: "author" | "book" | "organization";
  onCreate?: (newItem: MultiSelectOption) => void;
}

const MultiSelect = ({
  name,
  control,
  options,
  isCreatable = false,
  entityType,
  onCreate,
}: SelectProps) => {
  const [_, fetchData] = useFetch();
  const authenticationContext = useContext(AuthenticationContext);
  const SelectComponent = isCreatable ? CreatableSelect : Select;

  return (
    <div>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange, onBlur } }) => {
          const handleChange = (
            selectedOptions: MultiValue<MultiSelectOption>,
            actionMeta: any
          ) => {
            onChange && onChange(selectedOptions);
          };

          const handleCreate = async (label: string) => {
            debugger;
            if (!entityType) {
              return;
            }
            fetchData({
              url: `profile/api/${entityType}s/`,
              method: "POST",
              token: authenticationContext.token,
              body: JSON.stringify({ label }),
            }).then((response: any) => {
              onCreate && onCreate(response);
              onChange([...value, response]);
            });
          };

          return (
            <SelectComponent
              name={name}
              isMulti
              options={options}
              placeholder={"Choose..."}
              onChange={handleChange}
              value={value}
              className="basic-multi-select"
              classNamePrefix="select"
              onCreateOption={isCreatable ? handleCreate : undefined}
            />
          );
        }}
      />
    </div>
  );
};

export default MultiSelect;
