import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Team.scss";
import brookePhoto from "../../assets/img/team/brooke.jpeg";
import emilyPhoto from "../../assets/img/team/emily.jpg";
import judithPhoto from "../../assets/img/team/judith.jpeg";
import andreaPhoto from "../../assets/img/team/andrea.png";

const Team = () => {
  return (
    <Container className="team">
      <h1>The Inked Voices Team</h1>
      <p>
        Stories are magical. The journey to create them is hard work. We’re in
        the trenches with you, helping you make meaningful connections to fellow
        writers, bringing in speakers, agents, and editors for professional
        development, and cheering you on. We’re an online community, but a
        deeply human one. We work person-to-person to support our writers.
      </p>
      <Row className="founder">
        <Col sm={3}>
          <img alt="brooke" src={brookePhoto} />
        </Col>
        <Col sm={9}>
          <h4>Brooke McIntyre</h4>
          <h5>Founder and Principal</h5>
          <hr />
          <p>
            As a child, books were a way for me to meet new people and places,
            and quietly wrestle the questions spinning in my mind. The
            characters were everything and the author got my quick thanks for
            bringing them into being. Now I realize how much mastery it takes to
            create a captivating story, and the pluck required to share your
            authentic voice. Not to mention the fierceness to keep going!
          </p>
          <p>
            I write fiction and nonfiction picture books, with a side of poetry.
            If I get to imagine or explore a new world, I’m in. Especially if
            biology or ecology are involved. I also love to run, take walks and
            be on (or in!) the water. I’m slowly working on my garden and I grow
            oysters, too. In winter, I volunteer as an adaptive skiing coach. My
            family and I now live in Charlottesville, Virginia. You can find
            more about my writing at brookemcintyre.com.
          </p>
          <p>
            Inked Voices is my work, but also my community. It’s a huge pleasure
            to see what different writers are drawn to. I love that we are all
            such individuals, yet we’re doing the same basic process. I feel
            lucky to work alongside so many creative, inspiring individuals.{" "}
          </p>
        </Col>
      </Row>
      <Row className="members">
        <Col sm={12} md={6}>
          <Row className="member">
            <Col sm={4}>
              <img alt="andrea" src={andreaPhoto} />
            </Col>
            <Col sm={8}>
              <h4>Andrea Appleton</h4>
              <h5>Adult Fiction and Nonfiction Community Lead</h5>
              <hr />
              <p>
                As a journalist, I reported from remote tribal lands in
                northeastern Montana, a bug zoo in Japan, and the floor of the
                New York Stock Exchange, as well as many more mundane locales.
                Now that I am a fiction writer, my travels tend to be more
                internal. I’m slowly making my way through my first novel, set
                in the wilds of southern Colorado where I grew up. I now live in
                Baltimore, Maryland with my husband, two children, and our
                beloved pain-in-the-neck dog.
              </p>
              <p>
                In my free time, I enjoy hiking and camping, meditation, and
                tending my native plant garden. At Inked Voices, I work with
                those members who write for adults by connecting them with
                critique groups and other resources and, when I can, making
                serendipitous connections. I love helping writers find their
                community.
              </p>
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={6}>
          <Row className="member">
            <Col sm={4}>
              <img alt="emily" src={emilyPhoto} />
            </Col>
            <Col sm={8}>
              <h4>Emily Bailey</h4>
              <h5>Middle Grade and Young Adult Community Lead</h5>
              <hr />
              <p>
                I’m a mother, a wife, a yogi, a nature-lover, day-dreamer and
                story-weaver. For many years I blogged and wrote articles about
                nomadic mothering and womaning, while following my humanitarian
                husband around the world. We lived in seven different countries
                in fifteen years. I now live in the UK, where I mother three
                teenage/ young adult nomadic wildlings who come and go. Most
                days you’ll find me at my kitchen table with a zillion tabs open
                and a cup of hot English tea, commiserating about the weather
                and spinning words into stories. I’m currently working on my
                second MG novel. I also dabble in picture books and have
                co-written two screenplays.
              </p>
              <p>
                I love creating spaces which build human connection and create
                community and belonging, whether through events in my local
                community, or online, with Inked Voices. In 2024 I began my
                training to qualify as a Breathwork teacher and facilitator.
              </p>
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={6}>
          <Row className="member">
            <Col sm={4}>
              <img alt="judith" src={judithPhoto} />
            </Col>
            <Col sm={8}>
              <h4>Judith Valdés B.</h4>
              <h5>Picture Books Community Lead</h5>
              <hr />
              <p>
                Born and raised in México, I am an author-illustrator of
                children’s picture books. I draw on stories from my childhood
                and years living in Europe and Asia.
              </p>
              <p>
                In Inked Voices, I am dedicated to helping the picture book
                community find critique groups, assisting with their needs on
                the site, sharing information about workshops, and promoting
                mentorships. I enjoy helping people get a little closer to
                reaching their dreams.
              </p>
              <p>
                My debut picture book, An Ofrenda For Perro, is a 2024 NCTE
                Charlotte Huck Award Honor Book and was included in the best
                picture books published in 2023 by the Children's Committee of
                Bank Street College of Education.
              </p>
              <p>
                I currently live with my family in Mexico City and can usually
                be found in my studio or about town, writing, sketching,
                painting, or dreaming up my next project. For more about my
                writing and illustrating, visit judithvaldesb.com.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row></Row>
    </Container>
  );
};

export default Team;
