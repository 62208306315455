import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Col, Form, Row } from "react-bootstrap";
import AuthenticationContext from "contexts/AuthenticationContext";

import "./GroupNotificationSettings.scss";

import useFetch from "custom-hooks/useFetch";
import { toast } from "react-toastify";

interface GroupNotificationSettingsProps {
  id: number;
  daily_digest: boolean;
  new_submission: boolean;
  new_critique: boolean;
  new_discussion_or_reply: boolean;
  new_qa_comment: boolean;
  new_qa_comment_to_other: boolean;
  new_file: boolean;
  new_membership: boolean;
  critique_deadline_4_days: boolean;
  critique_deadline_2_days: boolean;
  critique_deadline_1_days: boolean;
  critique_deadline_today: boolean;
  critique_period_end: boolean;
  review_period_ending: boolean;
  membership: number;
}

const defaultNotificationSettings: GroupNotificationSettingsProps = {
  id: 0,
  daily_digest: false,
  new_submission: false,
  new_critique: false,
  new_discussion_or_reply: false,
  new_qa_comment: false,
  new_qa_comment_to_other: false,
  new_file: false,
  new_membership: false,
  critique_deadline_4_days: false,
  critique_deadline_2_days: false,
  critique_deadline_1_days: false,
  critique_deadline_today: false,
  critique_period_end: false,
  review_period_ending: false,
  membership: 0,
};


const GroupNotificationSettings = () => {
  const { groupId } = useParams() as any;
  const [state, fetchData] = useFetch();
  const [notificationSettings, setNotificationSettings] = useState<GroupNotificationSettingsProps>(defaultNotificationSettings);
  const authenticationContext = useContext(AuthenticationContext);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  useEffect(() => {
    fetchData({
      url: `group/api/notification-settings/${groupId}/`,
      method: "get",
      token: authenticationContext.token,
    })
    .then((response) => {
      setNotificationSettings(response);
    })
    .catch((error) => {
      console.log(error);
      toast.error("Failed to load notification settings.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    });
  }, [groupId, fetchData, authenticationContext.token]);

  const onSubmit = async (data: any) => {
    // Agrega el valor del input hidden a `data`
    const modifiedData = {
      ...data,
      membership: notificationSettings.membership, // Asegúrate de que este sea el nombre correcto del campo
      // Asegura que los valores de los checkboxes se envíen según el estado actual
      new_submission: notificationSettings.new_submission,
      new_critique: notificationSettings.new_critique,
      new_qa_comment: notificationSettings.new_qa_comment,
      new_qa_comment_to_other: notificationSettings.new_qa_comment_to_other,
      new_file: notificationSettings.new_file,
      critique_deadline_4_days: notificationSettings.critique_deadline_4_days,
      critique_deadline_2_days: notificationSettings.critique_deadline_2_days,
      critique_deadline_1_days: notificationSettings.critique_deadline_1_days,
      critique_deadline_today: notificationSettings.critique_deadline_today,
      critique_period_end: notificationSettings.critique_period_end,
      review_period_ending: notificationSettings.review_period_ending,
      new_membership: notificationSettings.new_membership,
      daily_digest: notificationSettings.daily_digest,
    };

    fetchData({
      url: `group/api/notification-settings/${groupId}/`,
      method: "post",
      token: authenticationContext.token,
      body: JSON.stringify(modifiedData),
    })
      .then(() => {
        toast.success("Success! Your submission has been saved.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      })
      .catch((error) => {
        toast.error("Failed to save changes.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  }

  const onCheckboxChange = (e: any) => {
    const { name, checked } = e.target;
    setNotificationSettings({
      ...notificationSettings,
      [name]: checked,
    });
  }

  return (
    <section id="membershipOptions">
      <div className="membership-page container">
        <h1 className="add-margin">Group Notifications Options</h1>

        <Form id="account-edit" method="POST" className="ajaxform form-horizontal" role="form" onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <div className="form-group" id="communication_pref">
              <Row style={{paddingBottom: 0}} >
                <Col sm={{ span: 9, offset: 2 }}>
                  <h3>Notify me when:</h3>
                </Col>
              </Row>

              <Row>
                <Col sm={{ span: 9, offset: 2 }} style={{paddingLeft: 36}}>
                  <input type="hidden" name="membership" value={notificationSettings.membership} />
                  <Form.Check
                    label="A group member submits a document for critique"
                    type={"checkbox"}
                    id={`inline-${"checkbox"}-1`}
                    checked={notificationSettings.new_submission}
                    {...register("new_submission", {
                      onChange: (e) => onCheckboxChange(e)
                    })}
                  />
                  <Form.Check
                    label="A group member submits a critique on my document"
                    type={"checkbox"}
                    checked={notificationSettings.new_critique}
                    id={`inline-${"checkbox"}-2`}
                    {...register("new_critique", {
                      onChange: (e) => onCheckboxChange(e)
                    })}
                  />
                  <Form.Check
                    label="A new Q&amp;A comment is posted on my manuscript"
                    type={"checkbox"}
                    checked={notificationSettings.new_qa_comment}
                    id={`inline-${"checkbox"}-3`}
                    {...register("new_qa_comment", {
                      onChange: (e) => onCheckboxChange(e)
                    })}
                  />
                  <Form.Check
                    label="A new Q&amp;A comment is posted on a group member's manuscript"
                    type={"checkbox"}
                    checked={notificationSettings.new_qa_comment_to_other}
                    id={`inline-${"checkbox"}-4`}
                    {...register("new_qa_comment_to_other", {
                      onChange: (e) => onCheckboxChange(e)
                    })}
                  />
                  <Form.Check
                    label="There is a new file uploaded to my group"
                    type={"checkbox"}
                    checked={notificationSettings.new_file}
                    id={`inline-${"checkbox"}-5`}
                    {...register("new_file", {
                      onChange: (e) => onCheckboxChange(e)
                    })}
                  />
                  <div>
                    <label style={{marginTop: 5}}>A critique deadline is coming up (will only be sent if you have not completed the critique)</label>
                    <br/>
                  </div>
                  <div className="profile-edit-indent">
                    <Form.Check
                      label="4 days away"
                      type={"checkbox"}
                      checked={notificationSettings.critique_deadline_4_days}
                      id={`inline-${"checkbox"}-6`}
                      {...register("critique_deadline_4_days", {
                        onChange: (e) => onCheckboxChange(e)
                      })}
                    />
                    <Form.Check
                      label="2 days away"
                      type={"checkbox"}
                      checked={notificationSettings.critique_deadline_2_days}
                      id={`inline-${"checkbox"}-7`}
                      {...register("critique_deadline_2_days", {
                        onChange: (e) => onCheckboxChange(e)
                      })}
                    />
                    <Form.Check
                      label="1 days away"
                      type={"checkbox"}
                      checked={notificationSettings.critique_deadline_1_days}
                      id={`inline-${"checkbox"}-8`}
                      {...register("critique_deadline_1_days", {
                        onChange: (e) => onCheckboxChange(e)
                      })}
                    />
                    <Form.Check
                      label="Today"
                      type={"checkbox"}
                      id={`inline-${"checkbox"}-9`}
                      checked={notificationSettings.critique_deadline_today}
                      {...register("critique_deadline_today", {
                        onChange: (e) => onCheckboxChange(e)
                      })}
                    />
                  </div>

                  <Form.Check
                    label="The critique period ends for a group member"
                    type={"checkbox"}
                    id={`inline-${"checkbox"}-10`}
                    checked={notificationSettings.critique_period_end}
                    {...register("critique_period_end", {
                      onChange: (e) => onCheckboxChange(e)
                    })}
                  />
                  <Form.Check
                    label="The review period on a document will end in 3 days"
                    type={"checkbox"}
                    id={`inline-${"checkbox"}-11`}
                    checked={notificationSettings.review_period_ending}
                    {...register("review_period_ending", {
                      onChange: (e) => onCheckboxChange(e)
                    })}
                  />
                  <Form.Check
                    label="A new member joins the group"
                    type={"checkbox"}
                    id={`inline-${"checkbox"}-12`}
                    checked={notificationSettings.new_membership}
                    {...register("new_membership", {
                      onChange: (e) => onCheckboxChange(e)
                    })}
                  />

                  <Form.Check
                    label="Send notifications from this group as a daily digest"
                    type={"checkbox"}
                    id={`inline-${"checkbox"}-13`}
                    className="daily-digest"
                    checked={notificationSettings.daily_digest}
                    {...register("daily_digest", {
                      onChange: (e) => onCheckboxChange(e)
                    })}
                  />
                </Col>
              </Row>
            </div>

            <Row style={{ marginTop: "1%" }}>
              <Col sm={{ span: 8, offset: 4 }} className="main-btn">
                <input type="submit" className="btn purple" value="Save" style={{ marginRight: "1%" }} />
                <a href="/" className="btn cancel">Cancel</a>
              </Col>
            </Row>
          </Row>
        </Form>
      </div>
    </section>
  );
}

export default GroupNotificationSettings;