import React, { useContext, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import "./ForumItem.scss";
import AuthenticationContext from "contexts/AuthenticationContext";
import useFetch from "custom-hooks/useFetch";
import { IComment } from "models/comment";
import Loading from "components/loading/Loading";
import { IUserData } from "models/user";
import { FormattedMessage } from "react-intl";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormControl,
  Image,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import sendRequest from "services/dataService";
import { ChatRightText } from "react-bootstrap-icons";
import Comment from "components/comment/Comment";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentPost } from "redux/postsReducer";
import Config from "../../config";
var W3CWebSocket = require('websocket').w3cwebsocket;


const ForumItem = () => {
  const history = useHistory();
  if (history.location.search != "") {
    history.replace(history.location.pathname);
  }

  const { id } = useParams() as any;
  const authenticationContext = useContext(AuthenticationContext);
  const [addCommentState, addCommentFetchData] = useFetch();
  const [commentTextState, setCommentTextState] = useState("");
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [scrolled, setScrolled] = useState(true);
  const [loadOlder, setLoadOlder] = useState(false);
  const [loadingOlder, setLoadingOlder] = useState(false);
  const [totalCalls, setTotalCalls] = useState(0);
  const [messageIndex, setMessageIndex] = useState<any>({});
  const [minId, setMinId] = useState(0);
  const [client, setClient] = useState<any>(null);
  const posts = useSelector((state: any) => state.posts);
  const postData = posts.currentPost;
  const lastConnections = useSelector((state: any) => state.lastConnections.last_connections);
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const dispatch = useDispatch();

  if (posts.posts != null) {
    dispatch(updateCurrentPost(posts.posts.filter((p: any) => p.id == id)[0]));
  }

  const scrollToBottom = () => {
    // if (messagesEndRef.current) {
    //   const scroll =
    //     messagesEndRef.current?.scrollHeight -
    //     messagesEndRef.current?.clientHeight;
    //   messagesEndRef.current?.scrollTo({
    //     behavior: "smooth",
    //     left: 0,
    //     top: scroll,
    //   });
    // }
  };

  const checkScrollPosition = (event: any) => {
    setTimeout(function () {
      const vl =
        event.target?.clientHeight + event.target?.scrollTop ==
        event.target?.scrollHeight;
      if (
        messagesEndRef.current?.scrollTop == event.target?.scrollTop &&
        vl != scrolled
      ) {
        setScrolled(vl);
      }
    }, 400);
  };

  const insertMessage = (message: any) => {
    if (message == null) {
      return null;
    }
    if (messageIndex[message.id] == null) {
      let newComments = [];
      let newMessageIndex = JSON.parse(JSON.stringify(messageIndex));
      newMessageIndex[message.id] = message;
      setMessageIndex(newMessageIndex);

      if (message.parent == null) {
        newComments = data.concat([message]).sort((a: any, b: any) =>
          a.date_created < b.date_created ? 1 : -1
        );
      } else {
        let hirarchy = [];
        let current = message;
        while (current.parent != null) {
          hirarchy.push(current.parent);
          current = messageIndex[current.parent];
        }
        newComments = [...data];
        let children = newComments;
        hirarchy.reverse().map(pid => {
          current = children.filter(c => c.id == pid)[0];
          children = current.children;
        });
        current.children = children.concat([message]).sort((a: any, b: any) =>
          a.date_created > b.date_created ? 1 : -1
        );
      }
      setData(newComments);
    }
  };

  const indexMessages = (messages: any, msgIndex: any) => {
    messages.map((c: any) => {
      msgIndex[c.id] = c;
      indexMessages(c.children, msgIndex);
    });
  }

  const updateIfNew = (result: any) => {
    let newComments = result.results.sort((a: any, b: any) =>
      a.date_created < b.date_created ? 1 : -1
    );
    let newMessageIndex = JSON.parse(JSON.stringify(messageIndex));
    indexMessages(newComments, newMessageIndex);
    setMessageIndex(newMessageIndex);

    const newMinId = newComments[newComments.length - 1]
      ? newComments[newComments.length - 1].id
      : 0;
    if (newMinId < minId) {
      newComments = data.concat(newComments);
      setMinId(newMinId);
    }
    if (minId == 0) {
      setMinId(newMinId);
    }
    if (newComments.length > 0) {
      setData(newComments);
    }

    setLoading(false);
    setLoadingOlder(false);
    setLoadOlder(false);
  };

  useEffect(() => {
    if (!client) {
      setClient(new W3CWebSocket(Config.WS_BASE_URL + id.toString() + "/"));
    } else {
      client.onopen = (e: any) => {
      };
      client.onerror = (e: any) => {
        console.error(e);
      }
      client.onclose = (e: any) => {
        fetchData();
        setTimeout(() => {
          setClient(new W3CWebSocket(Config.WS_BASE_URL + id.toString() + "/"))
        }, 5000);
      };
      client.onmessage = (message: any) => {
        const msg = JSON.parse(message.data);
        insertMessage(msg.message);
      };
    }
  }, [data, client]);

  const fetchData = async () => {
    let url = `forum/comments/`;
    if (loadOlder) {
      if (data[data.length - 1] == null) {
        setLoadOlder(false);
        return null;
      }
      setLoadingOlder(true);
      url =
        url +
        `?root_only=true&target_instance_id=${id}&max_id=${data[data.length - 1].id
        }&limit=10`;
    } else {
      if (minId == 0) {
        url = url + `?root_only=true&target_instance_id=${id}&limit=10`;
      } else {
        url =
          url +
          `?root_only=true&target_instance_id=${id}&min_id=${minId}&limit=1000`;
      }
    }
    const result = await sendRequest({
      url: url,
      method: "GET",
      token: authenticationContext.token || "",
      body: null,
    });
    updateIfNew(result);
    setTotalCalls(totalCalls + 1);
  };

  useEffect(() => {
    if (loading) {
      fetchData();
    }
  }, [totalCalls]);

  useEffect(() => {
    if (loadOlder && !loading && !loadingOlder) {
      fetchData();
    }
  });

  const loadOlderComments = () => {
    setLoadOlder(true);
  };

  const formatScrollDate = (current_day: string) => {
    const s = current_day.split("/");
    const itemDate = new Date(
      parseInt(s[2]),
      parseInt(s[0]) - 1,
      parseInt(s[1])
    );
    const now = new Date();
    const daysAgo = Math.floor(
      (now.getTime() - itemDate.getTime()) / 1000 / 60 / 60 / 24
    );
    if (daysAgo == 0) {
      return "Today";
    }
    if (daysAgo == 1) {
      return "Yesterday";
    }
    return daysAgo + " days ago";
  };

  useEffect(() => {
    if (scrolled && data.length > 0) {
      setTimeout(function () {
        scrollToBottom();
      }, 400);
    }
  }, [data]);

  let comments = <div>No comments</div>;
  let postTitle: string | undefined = "";
  let schedule: string | undefined = "";

  if (loading) {
    comments = (
      <Container>
        <Row className="justify-content-md-center">
          <Col md="auto">
            <Loading />
          </Col>
        </Row>
      </Container>
    );
  }

  if (!loading) {
    postTitle = postData?.name;
    schedule = postData?.schedule;
    let last_user = { avatar: "", id: 0 };
    let last_day = "";
    if (data.length == 0) {
      comments = (
        <>
          <Container className="ink-forum-item__message-container">
            <Row className="ink-forum-item__message-row ink-forum-item__no_messages">
              <div>
                <ChatRightText className="ink-forum-item__no_messages" />
              </div>
              <div>
                No comments yet...
                <br />
                Be the first one to post in this room!
              </div>
            </Row>
          </Container>
        </>
      );
    } else {
      comments = (
        <>
          {data.map((item: IComment) => {
            const current_day = new Date(item.date_created).toLocaleDateString(
              "en-US"
            );
            const comment = (
              <>
                {last_day != current_day && (
                  <Container className="ink-forum-item__message-container">
                    <Row className="ink-forum-item__message-row">
                      <span className="ink-forum-item__older-comments-date">
                        {formatScrollDate(current_day)}
                      </span>
                    </Row>
                  </Container>
                )}
                <Container className="ink-forum-item__message-container">
                  <Row className="ink-forum-item__message-row">
                    <Comment item={item} last_user={last_user} level={0} wsclient={client} insertMessage={insertMessage} />
                  </Row>
                </Container>
              </>
            );
            last_user = item.user;
            last_day = current_day;
            return comment;
          })}
          {data.length >= 10 && (
            <Container className="ink-forum-item__message-container">
              <Row className="ink-forum-item__message-row">
                {!loadingOlder && (
                  <Button
                    variant="light"
                    className="ink-forum-item__older-comments-button"
                    onClick={loadOlderComments}
                  >
                    Load older comments...
                  </Button>
                )}
                {loadingOlder && (
                  <span className="ink-forum-item__older-comments-button">
                    <Loading />
                  </span>
                )}
              </Row>
            </Container>
          )}
        </>
      );
    }
  }

  const handleSubmit = () => {
    addCommentFetchData({
      url: "forum/comments/",
      method: "POST",
      token: authenticationContext.token,
      body: JSON.stringify({
        target_instance_id: id,
        text: commentTextState
      }),
    }).then((res) => {
      setCommentTextState("");
      scrollToBottom();
      insertMessage(res);
      client.send(JSON.stringify({ message: res }));
    });

    if (addCommentState.isSuccess) {
      setCommentTextState("");
    }
  };

  const handleChange = (event: any) => {
    const target = event.target as HTMLInputElement;
    setCommentTextState(target.value);
  };

  const userImages = (users: IUserData[] | undefined) => {
    const renderTooltip = (props: any) => (
      <Tooltip id="button-tooltip" {...props}>
        {props.children}
      </Tooltip>
    );

    if (users && users.map) {
      return users.map((item) => (
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={() => renderTooltip(item.pen_name)}
        >
          <Image
            className="ink-forum-item__user-image"
            src={item.avatar}
            roundedCircle alt={item.pen_name}
          />
        </OverlayTrigger>
      ));
    }
  };

  return (
    <Container>
      <Row>
        <Col>
          <h1 className="ink-forum-item__title">{postTitle}</h1>
          {postData?.description && <p>{postData?.description}</p>}
          <Card className="ink-forum-item__card">
            <Card.Header className="ink-forum-item__card_header">
              <Container>
                <Row>
                  <Col>
                    <p className="ink-forum-item__schedule">
                      <span><FormattedMessage id="forumItem.schedule" />: </span>
                      {schedule}
                    </p>
                  </Col>
                  <Col>
                    <p className="ink-forum-item__schedule">
                      <span><FormattedMessage id="forumItem.onlineUsers" />: </span>
                      <span className="images-container">
                        {userImages(lastConnections)}
                      </span>
                    </p>
                  </Col>
                </Row>
              </Container>
            </Card.Header>
            <Card.Body>
              <Card.Text
                className="ink-forum-item__message-card"
                ref={messagesEndRef}
                onScroll={checkScrollPosition}
              >
                {comments}
              </Card.Text>
            </Card.Body>
            <Card.Footer className="sticky_footer">
              <Form>
                <div className="ink-forum-comment__reply_button_wrapper">
                  <Button
                    className="ink-forum-item__button"
                    variant="primary"
                    onClick={handleSubmit}
                  >
                    <FormattedMessage id="forumItem.form.addComment" />
                  </Button>
                </div>
                <Form.Group
                  controlId="formComment"
                  className="ink-forum-item__comment"
                >
                  <FormControl
                    as="textarea"
                    aria-label="Comment"
                    className="ink-forum-item__commentText"
                    value={commentTextState}
                    onChange={handleChange}
                    placeholder="Write a message..."
                  />
                </Form.Group>
              </Form>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ForumItem;
