import { displayDateTime, displayShortDate, getClosedEventMessage, isEventWithApplications, isLiveWriteIn, isResourceEvent, isProGroup } from "pages/events/utils";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Config from "config";
import { useSelector } from "react-redux";


const EventDetailButton = (props: any) => {
    const evt: any = props.event;
    const user = useSelector((state: any) => state.user);

    const getClosedEventButtonMessage = (evt: any) => {
        if (isEventWithApplications(evt)) {
            return "Coming Soon";
        }
        return "Coming Soon";
    }


   const getClosedEventButton = (evt: any) => {
        const message = getClosedEventButtonMessage(evt);
        let descriptionText = "";

        if (isProGroup(evt)) {
            descriptionText = evt?.registration_text;
        }

        return (
            <>
                <Button className="purple closed-event">
                    {message}
                </Button>
                <p>{getClosedEventMessage(evt)}</p>
                {descriptionText && <p dangerouslySetInnerHTML={{ __html: descriptionText }} />}
            </>
        );
    }


    const getOpenEventButton = (evt: any) => {
        if (evt.already_member) {
            return <>
                <Button className="purple">
                    Member
                </Button>
            </>
        }
        if (user.data.id && !user.data.has_active_subscription) {
            const posfix = user?.data.id ? (user.data.id + "/") : "";
            return <>
                <a href={Config.BASE_API_URL + "account/subscribe_new_user/" + posfix}>
                    <Button className="purple">
                        Members: renew subscription
                    </Button>
                </a>
            </>
        }
        if (evt.cannot_join_yet) {
            if (user.data.is_free_subscriber) {
                return <>
                    <p>{"Free members can't join"}</p>
                </>
            }
            return <>
                <Link to="login/" className="btn purple">Members: Log in to register</Link>
                <p>
                    Nonmembers may register on ${displayShortDate(evt.restricted_registration_end_date)}
                </p>
            </>
        }

        let btn_txt = "Register";
        let link = "";
        let descriptionText = "";
        let disabled = false;
        let target = "_self";
        if (isEventWithApplications(evt)) {
            btn_txt = "Apply";
            target = "_blank";
            link = evt.application_link;
            descriptionText = `Member applications open until ${displayDateTime(evt?.open_period_end_datetime)}`;
            if (evt?.registration_text != null) {
                descriptionText = descriptionText + '</br>' + evt?.registration_text;
            }
        } else if (isResourceEvent(evt)) {
            if (isLiveWriteIn(evt)) {
                btn_txt = "Join";
            }
            if (!user.data.id) {
                if (evt?.webinar_non_member_url) {
                    link = evt?.webinar_non_member_url;
                } else {
                    link = "#";
                    disabled = true;
                    btn_txt = "Not Available";
                    descriptionText = "Available to members only";
                }
            } else {
                link = evt?.webinar_url;
            }
            if (evt?.non_members_price) {
                descriptionText = `<b>Members</b>: Free</br><b>Nonmembers</b>: \$${evt?.non_members_price}`;
            }
        } else {
            const spt = evt.spots_left === 1 ? 'spot' : 'spots';
            btn_txt = (!evt.spots_left || evt.spots_left > 3) ? "Register" : `Register - ${evt.spots_left} ${spt} left`;
            link = Config.BASE_API_URL + "group/pre_pro_group_checkout/" + evt.id + "/";
            descriptionText = evt?.registration_text;
        }

        return <>
            <a href={link} target={target}>
                <Button className="green" disabled={disabled}>
                    {btn_txt}
                </Button>
            </a>
            <p dangerouslySetInnerHTML={{ __html: descriptionText }} />
        </>;
    }

    const getApplicationsClosedEventButton = (evt: any) => {
        return <Button className="closed-event purple">
            Applications Closed
        </Button>
    }

    const getFullEventButton = (evt: any) => {
        return <>
            <Button className="closed-event purple">
                Group Full
            </Button>
            <p>Email <a href="mailto:support@inkedvoices.com">support@inkedvoices.com</a> to join the waitlist</p>
        </>
    }

    if (evt?.status === "CLOSED") {
        return getClosedEventButton(evt);
    }
    if (evt?.status === "OPEN") {
        return getOpenEventButton(evt);
    }
    if (evt?.status === "APPLICATIONS_CLOSED") {
        return getApplicationsClosedEventButton(evt);
    }
    if (evt?.status === "FULL") {
        return getFullEventButton(evt);
    }
    if (evt?.status === "STARTED") {
        return <Button className="closed-event purple">
            Group Started
        </Button>
    }
    if (evt?.status === "FINISHED") {
        return <Button className="closed-event purple">
            Group Finished
        </Button>
    }
    return <></>;
}

export default EventDetailButton;