import React, { useState, useContext, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useFetch from "custom-hooks/useFetch";
import AuthenticationContext from "contexts/AuthenticationContext";
import { Alert, Form, FormControl, Button, Col, Image } from "react-bootstrap";
import { CaretDownFill, CaretUpFill, ReplyFill } from "react-bootstrap-icons";
import { IComment } from "models/comment";
import { FormattedMessage } from "react-intl";
import "./Comment.scss";
import { setOpenReplies } from "redux/openRepliesReducer";
import { setReplyTo } from "redux/replyToReducer";

interface IProps {
  last_user: any;
  item: IComment;
  level: number;
  wsclient: any;
  insertMessage: any;
}
const Comment = ({ last_user, item, level, wsclient, insertMessage }: IProps) => {
  const maxLevel = 2;
  const hasChildren = item.children.length > 0;
  const acceptsReplies = level < maxLevel;
  const authenticationContext = useContext(AuthenticationContext);
  const [replyText, setReplyText] = useState("");
  const [addCommentState, addCommentFetchData] = useFetch();
  const openReplies = useSelector((state: any) => state.openReplies);
  const replyTo = useSelector((state: any) => state.replyTo);
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user);
  const formatItemDate = (date_created: string) => {
    const now = new Date();
    const itemDate = new Date(date_created);
    if (itemDate > new Date(now.getTime() - 1000 * 10)) {
      return "Just now";
    }
    if (itemDate > new Date(now.getTime() - 1000 * 60)) {
      const secondsAgo = Math.floor(
        (now.getTime() - itemDate.getTime()) / 1000
      );
      return secondsAgo + " seconds ago";
    }
    if (itemDate > new Date(now.getTime() - 1000 * 60 * 60)) {
      const minutesAgo = Math.floor(
        (now.getTime() - itemDate.getTime()) / 1000 / 60
      );
      return minutesAgo + " minutes ago";
    }
    if (itemDate > new Date(now.getTime() - 1000 * 60 * 60 * 24)) {
      const hoursAgo = Math.floor(
        (now.getTime() - itemDate.getTime()) / 1000 / 60 / 60
      );
      return hoursAgo + " hours ago";
    }
    // if (itemDate > new Date(now.getTime() - 1000 * 60 * 60 * 24 * 31)) {
    //   const daysAgo = Math.floor(
    //     (now.getTime() - itemDate.getTime()) / 1000 / 60 / 60 / 24
    //   );
    //   return daysAgo + " days ago";
    // }
    // if (itemDate > new Date(now.getTime() - 1000 * 60 * 60 * 24 * 365)) {
    //   const monthsAgo = Math.floor(
    //     (now.getTime() - itemDate.getTime()) / 1000 / 60 / 60 / 24 / 30
    //   );
    //   return monthsAgo + " moths ago";
    // }
    // const yearsAgo = Math.floor(
    //   (now.getTime() - itemDate.getTime()) / 1000 / 60 / 60 / 24 / 365
    // );
    // return yearsAgo + " years ago";
    return itemDate.toLocaleTimeString("en-US");
  };

  const showReplies =
    (!openReplies?.open) ||
    openReplies.open[item.id] === true || (
      hasChildren &&
      openReplies.open[item.id] == null
    );

  const showReplyForm = replyTo?.showReplyForm[item.id] === true;
  const handleShowRepliesClick = () => {
    const newVal = !showReplies;
    dispatch(setOpenReplies(item.id, newVal));
  };

  const handleReplyClick = () => {
    const newVal = !showReplyForm;
    dispatch(setReplyTo(item.id, newVal));
    if (newVal) {
      executeScroll()
    }
  };

  const handleSubmitReply = () => {
    addCommentFetchData({
      url: "forum/comments/",
      method: "POST",
      token: authenticationContext.token,
      body: JSON.stringify({
        target_instance_id: item.target_instance_id,
        text: replyText,
        parent: item.id
      }),
    }).then((res) => {
      dispatch(setOpenReplies(item.id, true));
      dispatch(setReplyTo(item.id, false));
      setReplyText("");
      insertMessage(res);
      wsclient.send(JSON.stringify({ message: res }));
    });

    if (addCommentState.isSuccess) {
      setReplyText("");
    }
  };

  const handleReplyTextChange = (event: any) => {
    const target = event.target as HTMLInputElement;
    setReplyText(target.value);
  };

  const formRef = useRef<null | HTMLFormElement>(null);
  const executeScroll = () => {
    formRef?.current?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    if (showReplyForm) {
      executeScroll();
    }
  }, [formRef, showReplyForm])

  return (
    <>
      <Col className={"ink-forum-comment__image-container level_" + level}>
        <Image
          className="ink-forum-item__user-image--small"
          src={item.user.avatar}
          roundedCircle
        />
      </Col>
      <Col className={"ink-forum-comment__message level_" + level}>
        <div className={"ink-forum-comment__username"}>
          {item.user.pen_name}
          {" - "}
          <span className="date">{formatItemDate(item.date_created)}</span>
        </div>
        <Alert
          key={item.id}
          variant={"secondary"}
          className={"ink-forum-comment__comment"}
        >
          {item.text}
        </Alert>
        <div className="ink-forum-comment__date">
          <>
            <span
              className="ink-forum-comment__show_replies"
              onClick={handleShowRepliesClick}
            >
              {hasChildren && !showReplies && (
                <>
                  {`Show replies (${item.children.length})`}
                  <CaretDownFill />
                </>
              )}
              {hasChildren && showReplies && (
                <>
                  {`Hide replies (${item.children.length})`}
                  <CaretUpFill />
                </>
              )}
            </span>
            <span
              className="ink-forum-comment__reply"
              onClick={handleReplyClick}
            >
              {acceptsReplies && !showReplyForm && (
                <>
                  {`Reply `}
                  <ReplyFill />
                </>
              )}
              {acceptsReplies && showReplyForm && (
                <>
                  {`Hide reply `}
                  <ReplyFill />
                </>
              )}
            </span>
          </>
        </div>
        {showReplies && (
          <>
            {item.children.map((child) => (
              <Comment item={child} last_user={null} level={level + 1} wsclient={wsclient} insertMessage={insertMessage} />
            ))}
          </>
        )}
        {showReplyForm && (
          <div>
            <Col className={"ink-forum-comment__image-container level_" + (level + 1)}>
              <Image
                className="ink-forum-item__user-image--small"
                src={user?.data?.avatar}
                roundedCircle
              />
            </Col>
            <Form className="ink-forum-comment__comment_form" ref={formRef}>
              <div className="ink-forum-comment__reply_button_wrapper">
                <Button
                  className="ink-forum-comment__reply_button"
                  variant="primary"
                  onClick={handleSubmitReply}
                >
                  <FormattedMessage id="forumItem.form.reply" />
                </Button>
              </div>
              <Form.Group
                controlId="formComment"
                className="ink-forum-item__comment"
              >
                <FormControl
                  as="textarea"
                  aria-label="Comment"
                  className={"ink-forum-comment__commentText level_" + level}
                  value={replyText}
                  onChange={handleReplyTextChange}
                  placeholder="Write a reply..."
                />
              </Form.Group>
            </Form>
          </div>
        )}
      </Col>
      <Col className="ink-forum-item__spacer"></Col>
    </>
  );
};

export default Comment;
